





























































































































import { Component, Vue, Watch } from 'vue-property-decorator';
import { Inject } from 'inversify-props';
import _ from 'lodash';
import NotifyService, { NotifyServiceS } from '@/modules/common/services/notify.service';
import CustomSwitch from '@/modules/common/components/ui-kit/custom-switch.vue';
import CarsFiltersService, { CarsFiltersServiceS } from '@/modules/cars/cars-filters.service';
import CarsAlertService, { CarsAlertServiceS } from '@/modules/cars/car.alert.service';
import CustomSelect from '@/modules/common/components/ui-kit/custom-select-bordered.vue';
import { defaultPercentageVal, CarAlertType, CarAlertStatusType } from '@/modules/cars/modules/settings/config/alerts-notification.config';
import SIppCodeAlertConfigurationModel from '@/modules/cars/models/sippcode-alert-configuration.model';
import CustomMultiSelectBordered, { Item } from '@/modules/common/components/ui-kit/custom-multi-select-bordered.vue';
import LoaderWrapper from '@/modules/common/components/loader-wrapper.vue';
import { COUNTRIES_ANY } from '@/modules/cars/constants/car-filter-types.constant';

@Component({
    components: {
        CustomSwitch,
        CustomSelect,
        CustomMultiSelectBordered,
        LoaderWrapper,
    },
})
export default class SippCodeMappingAlertsSettings extends Vue {
    static title = 'SIPP Code Mapping Alerts';
    static subTitle = 'Send SIPP Code mapping alerts based on the following conditions:';

    @Inject(CarsFiltersServiceS) carsFiltersService!: CarsFiltersService;
    @Inject(CarsAlertServiceS) carsAlertService!: CarsAlertService;
    @Inject(NotifyServiceS) private notifyService!: NotifyService;

    isCarCategoryConfigChanged = false;
    isSippcodeConfigChanged = false;
    carNormalizedCountryCode: Item[] = [];
    carNormalizedUnknownTolerance: number = 0;
    carNormalizedBlankTolerance: number = 0;
    carNormalizedStatus: boolean = false;
    sippCountryCode: Item[] = [];
    sippCodeIgnorTolerance: number = 0;
    sippCodeBlankTolerance: number = 0;
    sippCodeStatus: boolean = false;

    get percentageVal() {
        return defaultPercentageVal;
    }

    get getCountryList() {
        const clustersKeys = this.carsFiltersService.getClustersKeys(true);

        if (!clustersKeys) {
            return [];
        }

        return clustersKeys;
    }

    get initAlertData() {
        const { data = [] } = this.carsAlertService;
        return data;
    }

    mounted() {
        this.onAlertUpdate();
    }

    @Watch('carsAlertService.data')
    @Watch('carsFiltersService.countryCodes')
    onAlertUpdate() {
        if (this.initAlertData && this.initAlertData.length > 0) {
            this.initAlertData.forEach(el => {
                if (el.alertType === CarAlertType.SIPP_CODE) {
                    this.sippCodeStatus = (el.status === CarAlertStatusType.ACTIVE);
                    this.sippCountryCode = this.getCountryList.filter(country => {
                        if (el.countryCodes.includes(country.value as string)) {
                            return {
                                name: String(country.name),
                                value: country.value,
                            };
                        }
                        return false;
                    });
                    this.sippCodeIgnorTolerance = el.extData.ignore_tolerance;
                    this.sippCodeBlankTolerance = el.extData.blank_tolerance;
                } else if (el.alertType === CarAlertType.CAR_CATEGORY) {
                    this.carNormalizedStatus = (el.status === CarAlertStatusType.ACTIVE);
                    this.carNormalizedCountryCode = this.getCountryList.filter(country => {
                        if (el.countryCodes.includes(country.value as string)) {
                            return {
                                name: String(country.name),
                                value: country.value,
                            };
                        }
                        return false;
                    });
                    this.carNormalizedUnknownTolerance = el.extData.unknown_tolerance;
                    this.carNormalizedBlankTolerance = el.extData.blank_tolerance;
                }
            });
        }
    }

    updateSippcodeChangeState() {
        this.isSippcodeConfigChanged = true;
    }

    updateCarCategoryChangeState() {
        this.isCarCategoryConfigChanged = true;
    }

    async save() {
        let success = false;
        if (this.isSippcodeConfigChanged) {
            success = await this.saveSippcodeAlert();
        }
        if (this.isCarCategoryConfigChanged) {
            success = await this.saveCarCategoryAlertConfiguration();
        }
        if (success) {
            this.carsAlertService.resetLoadings();
            this.isCarCategoryConfigChanged = false;
            this.isSippcodeConfigChanged = false;
            this.notifyService.success('The new configuration has been added successfully!');
        }
    }

    async saveSippcodeAlert(): Promise<boolean> {
        const sippCountryCodeSelected = this.sippCountryCode.map(country => country.value) as string[];
        if (this.sippCodeStatus && !sippCountryCodeSelected.length) {
            this.notifyService.error('Please select the country name');
            return false;
        }
        const sippCodeAlertBody: SIppCodeAlertConfigurationModel = {
            country_codes: sippCountryCodeSelected,
            status: this.sippCodeStatus ? CarAlertStatusType.ACTIVE : CarAlertStatusType.INACTIVATE,
            ext_data: {
                ignore_tolerance: this.sippCodeIgnorTolerance,
                blank_tolerance: this.sippCodeBlankTolerance,
            },
        };

        await this.carsAlertService.saveSippcodeAlert(sippCodeAlertBody);
        return true;
    }

    async saveCarCategoryAlertConfiguration(): Promise<boolean> {
        const carNormalizedCountryCodeSelected = this.carNormalizedCountryCode.map(country => country.value) as string[];
        if (this.carNormalizedStatus && !carNormalizedCountryCodeSelected.length) {
            this.notifyService.error('Please select the country name');
            return false;
        }
        const carNormalizedAlertBody: SIppCodeAlertConfigurationModel = {
            country_codes: carNormalizedCountryCodeSelected,
            status: this.carNormalizedStatus ? CarAlertStatusType.ACTIVE : CarAlertStatusType.INACTIVATE,
            ext_data: {
                unknown_tolerance: this.carNormalizedUnknownTolerance,
                blank_tolerance: this.carNormalizedBlankTolerance,
            },
        };

        await this.carsAlertService.saveCarCategoryAlertConfiguration(carNormalizedAlertBody);
        return true;
    }

    get isChanged(): boolean {
        return this.isSippcodeConfigChanged || this.isCarCategoryConfigChanged;
    }
}
